.a-heading {
  $root: &;

  position: relative;
  font-family: $font-family-raleway;
  font-size: 20px;
  font-weight: font-weight('extra-bold');
  line-height: 32px;
  color: $color-primary-navy;
  letter-spacing: 0;

  &--dot {
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background: $color-secondary-red;
      border-radius: 50%;
      transform: translate3d(14px, -14px, 0);
    }
    &#{$root}--large {
      &::after {
        transform: translate3d(18px, -18px, 0);
      }
    }
  }

  &--line {
    &::after {
      $line-width: 82px;

      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: $line-width;
      height: 3px;
      content: '';
      background: $color-primary-dark-navy;
      transform: translate3d(#{$line-width + 22px}, 0, 0);
    }
  }

  &--large {
    font-size: 30px;
    line-height: 48px;

    @include sp() {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
