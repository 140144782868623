@use 'sass-list';

// fonts related mixins
/* @mixin font-en-header() {
  font-family: $font-family-robinson;
  font-weight: $font-weight-bold;
}

@mixin font-en-header() {
  font-family: $font-family-kirimaya;
  font-weight: $font-weight-bold;
} */

/* @mixin font-body() {
  font-family: $font-family-bai-jamjuree;
  font-weight: $font-weight-regular;
} */

// responsive related mixins
@mixin pc() {
  @media (min-width: $WIDTH-TABLET + 1) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $WIDTH-SP + 1) and (max-width: $WIDTH-TABLET) {
    @content;
  }
}

@mixin sp() {
  @media (max-width: $WIDTH-SP) {
    @content;
  }
}

@mixin tabletOrLess() {
  @media (max-width: $WIDTH-TABLET) {
    @content;
  }
}
@mixin tabletOrMore() {
  @media (min-width: $WIDTH-SP + 1) {
    @content;
  }
}

@mixin wide-pc {
  @media (min-width: $breakpoint-small-pc) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $breakpoint-sp) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: $breakpoint-sp - 1) {
    @content;
  }
}
