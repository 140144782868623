.a-card {
  $root: &;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 162px;
  height: 260px;
  padding-top: 16px;
  padding-right: 11px;
  padding-bottom: 14px;
  padding-left: 11px;
  color: $color-primary-navy;
  cursor: pointer;
  background: $color-white;
  border-radius: 3px;
  transition: box-shadow 250ms;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    display: block;
    width: 0;
    content: '';
    background-color: hsla(0, 0%, 80%, 0.1);
    opacity: 0;
    transition: width 500ms ease-out, opacity 400ms ease-in;
  }

  &:hover {
    // box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    &::after {
      right: auto;
      left: 0;
      width: 100%;
      opacity: 1;
      transition: width 500ms ease-out, opacity 300ms ease-out;
    }
  }

  @include sp() {
    flex-direction: row;
    width: 302px;
    height: 152px;
    padding: 16px 23px 16px 26px;
  }

  &--large {
    width: 320px;
    height: 400px;
    padding-top: 20px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    background: rgba($color-grey-100, 0.95);
    border-radius: 0;
    border-radius: 3px;

    @include sp() {
      flex-direction: column;
      width: 302px;
      height: 388px;
      padding: 16px 20px 26px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-right: auto;
    margin-left: auto;

    img {
      // max-width: 100px;
      width: 120px;
      height: 120px;
    }

    @include sp() {
      width: 100px;
      height: 100px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    #{$root}--large & {
      @include sp() {
        width: 120px;
        height: 120px;

        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include sp() {
      margin-left: 32px;
    }

    #{$root}--large & {
      @include sp() {
        margin-left: 0;
      }
    }
  }

  &__title {
    margin-top: 16px;
    font-family: $font-family-raleway;
    font-size: 14px;
    font-weight: font-weight('bold');
    line-height: 24px;
    text-align: left;
    letter-spacing: 0;

    @include sp() {
      margin-top: 0;
    }

    #{$root}--large & {
      height: 64px;
      margin-top: 8px;
      font-size: 20px;
      font-weight: font-weight('extra-bold');
      line-height: 32px;
      text-align: left;

      @include sp() {
        height: 50px;
        margin-top: 14px;
        font-size: 18px;
        font-weight: font-weight('bold');
        line-height: 28px;
      }
    }
  }

  &__description {
    display: none;

    #{$root}--large & {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      line-height: 32px;
      text-align: left;

      @include sp() {
        margin-top: 16px;
      }
    }
  }

  &__action {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    #{$root}:hover & {
      .a-button {
        &__icon {
          background: $color-secondary-red;
        }
      }
    }

    @include sp() {
      right: 8px;
      bottom: 10px;
      margin: 0;
    }

    #{$root}--large & {
      right: 24px;
      bottom: 24px;

      .a-button {
        transform: translate(10px, 11px);
      }

      @include sp() {
        position: static;
        margin-top: 12px;
      }
    }
  }
}
