.a-back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  background: $color-grey-100;

  @include sp() {
    height: 120px;
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-primary-dark-navy;
    text-decoration: none;

    span {
      display: block;
      font-family: $font-family-raleway;
      font-size: 14px;
      font-weight: font-weight('bold');
      line-height: 16px;
      letter-spacing: 0;
    }

    .a-icon {
      margin-top: -3px;
      font-size: 32px;
    }

    &::after {
      position: absolute;
      top: 0;
      right: auto;
      bottom: 0;
      left: 0;
      display: block;
      width: 0;
      content: '';
      background-color: hsla(0, 0%, 80%, 0.1);
      opacity: 0;
      transition: width 500ms ease-out, opacity 400ms ease-in;
    }

    &:hover {
      &::after {
        right: 0;
        left: auto;
        width: 100%;
        opacity: 1;
        transition: width 500ms ease-out, opacity 300ms ease-out;
      }
    }
  }
}
