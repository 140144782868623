.o-container {
  width: #{($breakpoint-col-width-pc * 12) + ($breakpoint-gutter-pc * 13)};
  padding-right: $breakpoint-gutter-pc;
  padding-left: $breakpoint-gutter-pc;
  margin-right: auto;
  margin-left: auto;

  @include sp() {
    width: #{($breakpoint-col-width-sp * 4) + ($breakpoint-gutter-sp * 5)};
    padding-right: $breakpoint-gutter-sp;
    padding-left: $breakpoint-gutter-sp;
  }
}
