.o-global-header {
  $root: &;

  position: fixed;
  top: 32px;
  left: 0;
  z-index: $zindex-globalheader;
  width: 100%;

  @include sp() {
    top: 20px;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    width: 100vw;
    height: 100vh;
    // stylelint-disable-next-line value-no-vendor-prefix
    min-height: -webkit-fill-available;
    pointer-events: none;
    background: $color-primary-dark-navy;

    @include sp() {
      display: block;

      #{$root}--open & {
        display: block;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 760px;
    height: 56px;
    margin-right: auto;
    margin-left: auto;
    background: $color-white;
    border-radius: 100px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);

    @include sp() {
      max-width: 335px;
    }
  }

  &__brand {
    margin-left: 28px;
  }

  &__nav {
    display: block;
    width: 100%;
    margin-right: 48px;

    @include sp() {
      position: absolute;
      top: 56px;
      left: 0;
      height: calc(100vh - 58px);
      max-height: 0;
      margin-right: 0;
      overflow: hidden;
      pointer-events: none;
      transition: max-height 0.5s;

      #{$root}--open & {
        display: block;
        padding-bottom: 60px;
        overflow-x: hidden;
        overflow-y: auto;
        pointer-events: initial;
      }
    }

    &-toggle {
      display: none;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin-right: 4px;
      cursor: pointer;
      background: $color-secondary-red;
      border-radius: 50%;

      .a-icon {
        font-size: 48px;
      }

      @include sp() {
        display: inline-flex;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include sp() {
      flex-direction: column;
      margin-top: 20px;
    }

    &-item {
      margin-right: 16px;
      margin-left: 16px;
      font-weight: font-weight('medium');

      &:first-child {
        display: none;
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @include sp() {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        text-align: left;
        border-bottom: 1px solid rgba($color-line-blue-1, 10%);
        opacity: 0;

        &:first-child {
          display: flex;
        }
      }

      a {
        font-family: $font-family-raleway;
        color: $color-primary-dark-navy;
        letter-spacing: 0;

        @include sp() {
          display: block;
          width: 100%;
          max-width: 335px;
          padding: 20px 16px 20px;
          color: $color-white;
        }
      }
    }
  }

  &__bottom {
    display: none;
    max-width: 335px;
    padding: 0 16px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;

    @include sp() {
      display: block;
    }
  }
  &__share {
    &-label {
      font-family: $font-family-raleway;
      font-size: 16px;
      font-weight: font-weight('bold');
      color: $color-white;
      letter-spacing: 0;
    }

    &-links {
      margin-top: 20px;
    }
    &-link {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      span {
        display: none;
      }
      .a-icon {
        font-size: 32px;
      }
    }
  }
  &__legal {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 45px;
    margin-bottom: 75px;

    &-link {
      flex: 1;
      font-family: $font-family-raleway;
      color: $color-white;
      text-align: center;
      letter-spacing: 0;
    }
  }
}
