.p-product-list {
  overflow: hidden;

  &__head {
    position: relative;
    height: 712px;

    @include sp() {
      height: auto;
    }

    &-wrapper {
      height: 100%;
      & > .o-container {
        height: 100%;
        & > .o-row {
          height: 100%;
          & > .o-col {
            height: 100%;
          }
        }
      }
    }

    &-image {
      position: absolute;
      // width: 50%;
      left: 50%;
      width: 710px;
      margin-left: -720px;

      @include sp() {
        position: static;
        width: 100%;
        margin-left: 0;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      margin-top: -13px;

      @include sp() {
        padding-top: 77px;
        padding-bottom: 70px;
        margin-top: 0;
      }

      &-description {
        color: $color-primary-navy;

        @include sp() {
          margin-top: 8px;
        }
      }
    }
  }

  &__products {
    position: relative;
    padding-bottom: 75px;
    margin-top: -80px;

    @include sp() {
      margin-top: 0;
    }

    &-wrapper {
      position: relative;
      z-index: 1;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include sp() {
        grid-template-columns: repeat(1, 1fr);
      }

      &-item {
        margin-bottom: 25px;

        @include sp() {
          margin-bottom: 20px;
        }

        // .a-card {
        //   height: 100%;
        // }

        &--left {
          .a-card {
            margin-right: 12.5px;
            margin-left: 0;
            @include sp() {
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
        &--center {
          .a-card {
            margin-right: 12.5px;
            margin-left: 12.5px;
            @include sp() {
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
        &--right {
          .a-card {
            margin-right: 0;
            margin-left: 12.5px;
            @include sp() {
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &__back {
    position: relative;
    z-index: 1;
  }

  &__graphic {
    position: absolute;
    bottom: -94px;
    left: 50%;
    width: 840px;
    height: 1052px;
    transform: translateX(540px);

    @include sp() {
      bottom: -280px;
      left: 0;
      transform: none;
    }

    ellipse {
      isolation: isolate;
      mix-blend-mode: multiply;
    }
  }
}
