.o-whole {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: #{($breakpoint-col-width-pc * 12) + ($breakpoint-gutter-pc * 13)};
  max-width: 1800px;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  background: $color-white;
  box-shadow: 0 3px 76px rgba(0, 0, 0, 0.1);

  @include sp() {
    min-width: #{($breakpoint-col-width-sp * 4) + ($breakpoint-gutter-sp * 5)};
  }
}
