.app-main {
  &__header {
    width: 100%;
  }
  &__content {
    width: 100%;
  }
  &__footer {
    width: 100%;
  }
}

.o-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 142px;

  @include sp() {
    flex-direction: column;
    height: auto;
    padding-top: 40px;
    padding-bottom: 32px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1256px;
    max-width: 1800px;
    height: 100%;
    background: rgba($color-primary-dark-navy, 0.9);

    @include sp() {
      flex-direction: column;
      width: 100%;
      min-width: auto;
    }
  }

  &__content {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 24px;
    color: $color-white;

    a {
      text-decoration: underline;
    }
  }

  &__buttons {
    flex: 0 0 309px;
    padding-right: 20px;
    text-align: right;

    @include sp() {
      flex: auto;
      padding-right: 0;
      margin-top: 32px;
    }
  }

  &__button {
    width: 195px;
    height: 40px;
    font-family: $font-family-raleway;
    font-size: 13px;
    font-weight: font-weight('medium');
    letter-spacing: 0.7px;
    cursor: pointer;
    background: $color-white;
    border-radius: 28px;

    @include sp() {
      width: 294px;
      height: 56px;
    }
  }
}
