/**
 * Colors
 *
 * * `primary` and `secondary` ==> For UI, decoration (exclude for graph)
 * * `text` ==> For text
 * * `base` ==> For Background
 * * `shadow` ==> For Box Shadow
 * * `line` ==> For border (e.g. hr, table)
 * * `graph` ==> For graph
 *
 * NOTE: Names (except for "primary") are from `yarn utils:get-color-name`
 */

// Greyscale
$color-white: #ffffff;
$color-grey-100: #f6f6f6;
$color-grey-200: #e3e3e3;
$color-grey-300: #7e7e7e;
$color-grey-400: #444445;
$color-black: #1a1311;

// Primary Colors
$color-primary-black-navy: #051e43;
$color-primary-dark-navy: #17294a;
$color-primary-navy: #1d2e77;
$color-primary-dark-blue: #255dab;
$color-primary-blue: #0092e5;
$color-primary-light-blue: #f7f9fd;

// Secondary Colors
$color-secondary-dark-red: #b40019;
$color-secondary-red: #df1f1c;
$color-secondary-dark-orange: #e64800;
$color-secondary-orange: #f38200;
$color-secondary-light-orange: #fca518;
$color-secondary-yellow: #ffe900;
$color-secondary-green: #399d26;
$color-secondary-dark-green: #318821;

// Background
$color-bg-pale-blue: #f4f5f7;
$color-bg-dark-grey: #242424;

// Text
$color-text-black: $color-black;
$color-text-white: $color-white;

// Line / Border
$color-line-white: $color-white;
$color-line-black: $color-black;
$color-line-blue-1: #c0c5d9;

// background
$color-base-white: $color-white;
