.o-global-footer {
  $root: &;

  display: flex;
  flex-direction: column;
  width: 100%;

  &__logo {
    @include sp() {
      margin-bottom: 40px;
      text-align: center;
      img {
        width: auto;
        height: 52px;
      }
    }
  }

  &__content {
    padding-top: 32px;
    padding-bottom: 60px;
    background: $color-white;

    @include sp() {
      padding-top: 40px;
      padding-bottom: 37px;
    }
  }

  &__links {
    $links: &;

    &-title {
      font-family: $font-family-raleway;
      font-size: 14px;
      font-weight: font-weight('bold');
      line-height: 16px;
      color: $color-primary-dark-navy;
      letter-spacing: 0;

      @include sp() {
        font-size: 18px;
        line-height: 21px;
      }
    }
    &-items {
      margin-top: 10px;

      @include sp() {
        margin: 0;
      }
    }
    &-item {
      font-size: 12px;
      line-height: 24px;
      color: $color-primary-dark-navy;

      .a-icon {
        margin-left: 11px;
        transition: transform 250ms;
      }

      &:hover {
        .a-icon {
          transform: translateX(2px);
        }
      }
    }

    &--link-group {
      #{$links}-title {
        .a-icon {
          display: none;
        }
      }
      @include sp() {
        padding-top: 17px;
        padding-bottom: 18px;
        border-top: 1px solid $color-line-blue-1;

        #{$links}-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .a-icon {
            display: block;
            font-size: 32px;
          }
        }

        #{$links}-items {
          max-height: 0;
          overflow: hidden;

          #{$links}-item {
            font-size: 16px;
            line-height: 32px;
          }

          .o-row {
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    &--opened {
      @include sp() {
        #{$links}-items {
          // max-height: none;
          margin-top: 24px;
        }
      }
    }

    &--contact {
      @include sp() {
        margin-top: 10px;
      }

      #{$links}-title {
        a {
          position: relative;
          .a-icon {
            position: absolute;
            right: 20px;
            display: none;
            font-size: 30px;
          }
          @include sp() {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            border: 1px solid $color-line-blue-1;
            border-radius: 3px;
            .a-icon {
              display: block;
            }
          }
        }
      }
    }

    &--social {
      @include sp() {
        margin-top: 32px;

        #{$links}-items {
          margin-top: 31px;
        }
      }
    }
  }

  &__socials {
    @include sp() {
      display: flex;
      justify-content: space-between;
    }
    li {
      display: inline-flex;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      @include sp() {
        margin-right: 0;
      }

      a {
        font-size: 20px;
        opacity: 1;
        transition: opacity 400ms;

        &:hover {
          opacity: 0.7;
        }

        span {
          display: none;
        }

        @include sp() {
          font-size: 38px;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: $color-primary-light-blue;

    .o-col {
      display: flex;
      align-items: center;
    }

    @include sp() {
      height: 70px;
      padding-top: 16px;
      padding-bottom: 16px;
      .o-col {
        justify-content: center;
      }
    }
  }

  &__nttdata-logo {
    @include sp() {
      width: auto;
      height: 12px;
    }
  }

  &__copy {
    width: 100%;
    padding-right: 20px;
    font-size: 12px;
    line-height: 12px;
    color: rgba($color-primary-dark-navy, 0.65);
    text-align: right;

    @include sp() {
      padding: 0;
      margin-top: 14px;
      text-align: center;
    }
  }
}
