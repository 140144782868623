.o-section-case-study {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 720px;
  background-color: $color-primary-dark-navy;

  @include sp() {
    height: auto;
    padding-bottom: 112px;
  }

  & > .o-container {
    height: 100%;
    & > .o-row {
      height: 100%;
      & > .o-col {
        height: 100%;
      }
    }
  }

  &__heading-col {
    @include sp() {
      order: 1;
    }
  }

  &__heading {
    margin-top: 95px;

    @include sp() {
      margin-top: 77px;
    }
    .a-heading {
      color: $color-white;
      white-space: nowrap;
    }
  }

  &__text-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include sp() {
      order: 3;
    }
  }
  &__text {
    position: relative;
    top: 33px;
    left: -17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include sp() {
      left: auto;
      margin-top: -8px;
    }

    &-title {
      color: $color-white;

      @include sp() {
        margin-right: -20px;
      }
    }
    &-subtitle {
      padding-left: 4px;
      margin-top: 0;
      color: $color-white;

      @include sp() {
        margin-top: 8px;
      }
    }
    &-action {
      padding-left: 4px;
      margin-top: 123px;

      @include sp() {
        margin-top: 105px;
      }
    }
  }

  &__image-col {
    height: 100%;

    @include sp() {
      order: 2;
    }
  }

  &__image {
    position: relative;
    height: 100%;

    @include sp() {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      margin-right: -40px;
      margin-left: -40px;
    }

    &-item {
      position: absolute;
      top: 50%;
      left: 0;
      width: 710px;
      height: 414px;
      margin-top: -207px;
      background: $color-white;

      @include sp() {
        position: static;
        margin-top: 0;
      }

      picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      video {
        filter: blur(2px);
        transform: scale(1.75);
      }

      @include sp() {
        width: 375px;
        height: 304px;
      }
    }

    &-text {
      position: absolute;
      right: 0;
      bottom: -48px;
      font-family: $font-family-raleway;
      font-size: 300px;
      font-style: normal;
      font-weight: font-weight('extra-bold');
      line-height: 1;
      color: $color-white;
      letter-spacing: 0;

      @include sp() {
        top: -78px;
        bottom: auto;
        font-size: 200px;
        font-weight: font-weight('bold');
      }
    }
  }
}
.p-case-study {
  overflow: hidden;

  &__head {
    .o-section-case-study {
      position: relative;
      height: 712px;

      @include sp() {
        height: auto;
        padding-top: 180px;
        padding-bottom: 100px;
      }

      &__text-col {
        align-items: flex-start;
        margin-top: -12px;
      }
      &__heading {
        margin-top: 90px;

        @include sp() {
          position: absolute;
          top: 65px;
        }
      }
      &__text {
        top: 28px;
        left: 0;

        @include sp() {
          margin-top: 17px;
        }
        &-subtitle {
          margin-top: 48px;
          @include sp() {
            margin-top: 27px;
          }
        }
      }
      &__image {
        @include sp() {
          margin-top: 206px;
        }
        &-item {
          margin-top: -178px;
        }
        &-text {
          bottom: 20px;
          font-size: 200px;
          font-weight: font-weight('bold');

          @include sp() {
            top: auto;
            right: 10px;
            bottom: -58px;
          }
        }
      }
    }
  }

  &__intro {
    padding-top: 115px;
    padding-bottom: 83px;

    @include sp() {
      padding-top: 75px;
      padding-bottom: 80px;
    }

    &-text {
      width: 700px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
      line-height: 32px;
      color: $color-primary-navy;
      text-align: center;

      @include sp() {
        width: 100%;
        margin-top: 80px;
        margin-top: 0;
        font-family: $font-family-raleway;
        font-size: 24px;
        line-height: 39px;
        letter-spacing: 0;
      }
    }

    &-solution {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 54px;

      @include sp() {
        margin-top: 75px;
      }

      &-title {
        position: relative;
        margin-bottom: 18px;
        font-family: $font-family-raleway;
        font-size: 14px;
        font-weight: font-weight('extra-bold');
        line-height: 32px;
        line-height: 16px;
        color: $color-primary-navy;
        letter-spacing: 2px;

        &::after {
          position: absolute;
          bottom: -28px;
          left: 50%;
          width: 1px;
          height: 16px;
          content: '';
          background: $color-primary-navy;
        }
      }
      &-links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 26px;
      }
      &-link {
        display: inline-block;
        padding: 8px 16px;
        margin-right: 8px;
        margin-bottom: 16px;
        margin-left: 8px;
        font-size: 16px;
        line-height: 17px;
        color: $color-primary-navy;
        white-space: nowrap;
        background: rgba($color-primary-dark-navy, 16%);
        border-radius: 16px;
        transition: background 400ms, color 400ms;

        &:hover {
          color: $color-white;
          text-decoration: none;
          background: $color-primary-black-navy;
        }
      }
    }
  }

  &__background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 114px;
    padding-bottom: 116px;
    overflow: hidden;
    color: $color-white;

    @include sp() {
      padding-top: 94px;
      padding-bottom: 86px;
    }

    &-graphic {
      position: absolute;
      pointer-events: none;

      svg {
        width: 100%;
        height: auto;
      }

      &--1 {
        top: -372px;
        left: 50%;
        width: 840px;
        height: 105px;
        transform: translateX(-1244px);

        @include sp() {
          top: -754px;
          transform: translateX(-724px);
        }
      }

      &--2 {
        top: -8px;
        left: 50%;
        width: 719px;
        height: 912px;
        transform: translateX(448px);

        @include sp() {
          top: auto;
          bottom: -450px;
          transform: translateX(-70px);
        }
      }
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-primary-dark-navy, 75%);
    }

    &-title {
      position: relative;
      font-family: $font-family-raleway;
      font-size: 30px;
      font-weight: font-weight('extra-bold');
      line-height: 48px;
      text-align: center;
      letter-spacing: 0;
    }

    &-summary {
      position: relative;
      width: 515px;
      margin-top: 22px;
      margin-right: auto;
      margin-left: auto;
      font-family: $font-family-raleway;
      font-size: 22px;
      font-weight: font-weight('bold');
      line-height: 32px;
      text-align: center;
      letter-spacing: 0;

      @include sp() {
        width: 100%;
        margin-top: 31px;
      }
    }

    &-list {
      position: relative;
      margin-top: 30px;
      @include sp() {
        margin-top: 30px;
      }
    }
    &-item {
      position: relative;

      @include sp() {
        margin-bottom: 6px;
        margin-left: 15px;
      }

      &::before {
        position: absolute;
        top: 7px;
        left: -20px;
        width: 6px;
        height: 6px;
        content: '';
        background: $color-secondary-red;
        border-radius: 50%;
      }
    }
  }

  &__effort {
    padding-top: 124px;
    padding-bottom: 120px;

    @include sp() {
      padding-top: 123px;
      padding-bottom: 92px;
    }

    &-title {
      font-family: $font-family-raleway;
      font-size: 30px;
      font-weight: font-weight('extra-bold');
      line-height: 30px;
      color: $color-primary-navy;
      text-align: center;
      letter-spacing: 0;
    }

    &-list {
      margin-top: 80px;

      @include sp() {
        margin-top: 40px;
      }
    }

    &-item {
      margin-bottom: 120px;
      &:last-child {
        margin-bottom: 0;
      }

      @include sp() {
        margin-bottom: 72px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-image-col {
      @include sp() {
        width: 100vw;
        margin-right: -20px;
        margin-left: -20px;
      }
    }

    &-image {
      width: 100%;
      height: auto;
    }

    &-text {
      font-family: $font-family-raleway;
      font-size: 22px;
      font-weight: font-weight('bold');
      line-height: 32px;
      color: $color-primary-navy;
      letter-spacing: 0;

      @include sp() {
        margin-top: 37px;
      }
    }

    &-detail {
      margin-top: 11px;

      @include sp() {
        margin-top: 10px;
      }

      li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 4px;
        color: $color-primary-navy;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          position: absolute;
          top: 8px;
          left: 0;
          width: 6px;
          height: 6px;
          content: '';
          background: $color-secondary-red;
          border-radius: 50%;
        }
      }
    }
  }

  &__effect {
    padding-top: 114px;
    background: $color-bg-pale-blue;

    @include sp() {
      padding-top: 123px;
    }

    &-title {
      font-family: $font-family-raleway;
      font-size: 30px;
      font-weight: font-weight('extra-bold');
      line-height: 30px;
      color: $color-primary-navy;
      text-align: center;
      letter-spacing: 0;
    }

    &-summary {
      width: 668px;
      margin-top: 40px;
      margin-right: auto;
      margin-left: auto;
      font-family: $font-family-raleway;
      font-size: 22px;
      font-weight: font-weight('bold');
      line-height: 32px;
      color: $color-primary-navy;
      text-align: center;
      letter-spacing: 0;

      @include sp() {
        width: 280px;
        margin-top: 39px;
      }
    }

    &-summary-2 {
      width: 720px;
      margin-top: 30px;
      color: $color-primary-navy;

      @include sp() {
        width: 100%;
        margin-top: 30px;
      }
    }

    &-list {
      margin-top: 70px;

      @include sp() {
        margin-top: 68px;
      }
    }

    &-item {
      margin-bottom: 120px;
      &:first-child {
        margin-bottom: 90px;
        @include sp() {
          margin-bottom: 72px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      @include sp() {
        margin-bottom: 88px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-image-col {
      @include sp() {
        width: 100vw;
        margin-right: -20px;
        margin-left: -20px;
      }
    }

    &-image {
      width: 100%;
      height: auto;
    }

    &-text {
      margin-top: -11px;
      font-family: $font-family-raleway;
      font-size: 22px;
      font-weight: font-weight('bold');
      line-height: 32px;
      color: $color-primary-navy;
      letter-spacing: 0;

      @include sp() {
        margin-top: 37px;
      }
    }

    &-description {
      margin-top: 20px;
      color: $color-primary-navy;

      @include sp() {
        margin-top: 10px;
      }
    }
  }

  &__highlight {
    position: relative;
    height: 616px;
    overflow: hidden;
    background: $color-bg-pale-blue;

    @include sp() {
      height: 733px;
    }

    &-bg {
      position: absolute;
      bottom: -3052px;
      left: 50%;
      width: 100%;
      margin-left: -1713px;

      @include sp() {
        bottom: -2913px;
      }
    }

    &-icon {
      position: absolute;
      top: 155px;
      left: 50%;
      z-index: 1;
      margin-left: -60px;

      @include sp() {
        top: 132px;
      }
    }

    &-content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 368px;
      margin-top: 235px;
      color: $color-white;
      text-align: center;

      @include sp() {
        height: 525px;
        margin-top: 208px;
      }
    }

    &-title {
      margin-top: 122px;
      font-family: $font-family-raleway;
      font-size: 30px;
      font-weight: font-weight('extra-bold');
      line-height: 30px;
      text-align: center;
      letter-spacing: 0;

      @include sp() {
        margin-top: 128px;
      }
    }

    &-description {
      width: 720px;
      margin-top: 18px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
      line-height: 32px;

      @include sp() {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}
