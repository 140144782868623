.m-tabs {
  --length: 1;
  --active-index: 0;
  --indicator-left: 0;

  @include sp() {
    width: 322px;
    margin-right: auto;
    margin-left: auto;
  }

  &__tab-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include sp() {
      width: 100vw;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__tab-list {
    display: flex;
    width: 100%;

    @include sp() {
      width: auto;
    }
  }

  &__tab {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0 3px;
    font-family: $font-family-raleway;
    font-size: 30px;
    font-weight: font-weight('regular');
    line-height: 48px;
    color: $color-primary-navy;
    text-align: center;
    letter-spacing: 0;
    cursor: pointer;

    @include sp() {
      margin-right: 15px;
      margin-left: 15px;
      font-size: 20px;
      line-height: 32px;
      white-space: nowrap;
    }

    &--active {
      font-weight: font-weight('bold');
    }
  }

  &__indicator {
    position: relative;
    width: 100%;
    height: 3px;

    @include sp() {
      margin-top: 5px;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: calc((33% / 2) - (72px / 2) + (100% / var(--length) * var(--active-index)));
      width: 72px;
      height: 3px;
      content: '';
      background-color: $color-secondary-red;
      border-radius: 2px;
      transition-duration: 0.3s;
      transition-property: left;

      @include sp() {
        left: var(--indicator-left);
      }
    }
  }

  &__content {
    display: none;
    margin-top: 32px;

    @include tabletOrMore {
      padding-right: 30px;
      padding-left: 30px;
    }

    &--active {
      display: block;
    }
  }
}
