.p-app {
  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
  }
}
.a-list {
  li {
    position: relative;
    padding-left: 20px;
    margin: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '◆';
    }
  }
}

// Generic
.a-color {
  &__bg {
    &--primary-dark-navy {
      background: $color-primary-dark-navy;
    }
    &--primary-navy {
      background: $color-primary-navy;
    }
    &--primary-dark-blue {
      background: $color-primary-dark-blue;
    }
    &--primary-blue {
      background: $color-primary-blue;
    }
    &--primary-light-blue {
      background: $color-primary-light-blue;
    }

    // Secondary Colors
    &--secondary-dark-red {
      background: $color-secondary-dark-red;
    }
    &--secondary-red {
      background: $color-secondary-red;
    }
    &--secondary-dark-orange {
      background: $color-secondary-dark-orange;
    }
    &--secondary-orange {
      background: $color-secondary-orange;
    }
    &--secondary-light-orange {
      background: $color-secondary-light-orange;
    }
    &--secondary-yellow {
      background: $color-secondary-yellow;
    }
    &--secondary-green {
      background: $color-secondary-green;
    }
    &--secondary-dark-green {
      background: $color-secondary-dark-green;
    }

    // Greyscale
    &--white {
      background: $color-white;
    }
    &--grey-100 {
      background: $color-grey-100;
    }
    &--grey-200 {
      background: $color-grey-200;
    }
    &--grey-300 {
      background: $color-grey-300;
    }
    &--grey-400 {
      background: $color-grey-400;
    }
    &--black {
      background: $color-black;
    }
  }
}

@import './app';
@import './home';
@import './service-list';
@import './product-list';
@import './product-detail';
@import './case-study';
@import './about';
@import './contact-us';
@import './legal';
