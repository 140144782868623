@use "sass:math";
$gutter-pc: math.div($breakpoint-gutter-pc, 2);
$gutter-sp: math.div($breakpoint-gutter-sp, 2);

.o-col {
  width: 100%;

  @for $i from 1 through 12 {
    &--#{$i} {
      @include tabletOrMore() {
        width: #{($breakpoint-col-width-pc * $i) + ($breakpoint-gutter-pc * ($i - 1))};
        margin-right: $gutter-pc;
        margin-left: $gutter-pc;
      }
    }
  }

  @for $i from 1 through 4 {
    &--sp-#{$i} {
      @include sp() {
        width: #{($breakpoint-col-width-sp * $i) + ($breakpoint-gutter-sp * ($i - 1))};
        margin-right: $gutter-sp;
        margin-left: $gutter-sp;
      }
    }
  }
}
