* {
  padding: 0;
  margin: 0;
}

html,
body {
  // height: 100%;
}

body {
  margin: 0;
  font-family: $font-family-arial;
  font-size: 16px;
  line-height: 32px;
  color: $color-text-black;
  letter-spacing: 0.2px;
  background-color: $color-base-white;
}

// NOTE: Overwrite sanitize.css
h1 {
  margin: 0;
  font-family: $font-family-raleway;
  font-size: 60px;
  font-weight: font-weight('medium');
  line-height: 80px;
  letter-spacing: 0;

  @include sp() {
    font-size: 40px;
    line-height: 58px;
  }
}
h2 {
  font-family: $font-family-raleway;
  font-size: 40px;
  font-weight: font-weight('medium');
  line-height: 54px;
  letter-spacing: 0;

  @include sp() {
    font-size: 24px;
    line-height: 39px;
  }
}
h3 {
  font-family: $font-family-raleway;
  font-size: 30px;
  font-weight: font-weight('bold');
  line-height: 48px;
  letter-spacing: 0;

  @include sp() {
    font-size: 22px;
    line-height: 36px;
  }
}
h4 {
  font-family: $font-family-raleway;
  font-size: 22px;
  font-weight: font-weight('bold');
  line-height: 36px;
  letter-spacing: 0;

  @include sp() {
    font-size: 20px;
    line-height: 32px;
  }
}
h5 {
  font-family: $font-family-raleway;
  font-size: 20px;
  font-weight: font-weight('extra-bold');
  line-height: 32px;
  letter-spacing: 0;

  @include sp() {
    font-size: 18px;
    line-height: 28px;
  }
}
h6 {
  font-family: $font-family-raleway;
  font-size: 14px;
  font-weight: font-weight('bold');
  line-height: 24px;
  letter-spacing: 0;
}

ul,
ol,
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

em {
  font-style: normal;
}

// Overwrite sanitize.css
html {
  line-height: normal;
}

input,
select {
  word-break: break-all; // To sanitize input's behavior on Safari
  border: none;
  border-radius: 0;
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

#app-root {
  min-width: $breakpoint-small-pc;
  height: 100%;
  @include sp() {
    min-width: 0;
  }
}

nav li::before {
  display: none;
}
