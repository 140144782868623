.a-text {
  &,
  &--body-2 {
    font-size: 16px;
    line-height: 32px;
  }
  &--body-1 {
    font-size: 20px;
    line-height: 32px;

    @include sp() {
      font-size: 19px;
      line-height: 36px;
    }
  }
  &--caption {
    font-size: 12px;
    line-height: 24px;
  }
  &--h1 {
    margin: 0;
    font-family: $font-family-raleway;
    font-size: 60px;
    font-weight: font-weight('medium');
    line-height: 80px;
    letter-spacing: 0;

    @include sp() {
      font-size: 40px;
      line-height: 58px;
    }
  }
  &--h2 {
    font-family: $font-family-raleway;
    font-size: 40px;
    font-weight: font-weight('medium');
    line-height: 54px;
    letter-spacing: 0;

    @include sp() {
      font-size: 24px;
      line-height: 39px;
    }
  }
  &--h3 {
    font-family: $font-family-raleway;
    font-size: 30px;
    font-weight: font-weight('bold');
    line-height: 48px;
    letter-spacing: 0;

    @include sp() {
      font-size: 22px;
      line-height: 36px;
    }
  }
  &--h4 {
    font-family: $font-family-raleway;
    font-size: 22px;
    font-weight: font-weight('bold');
    line-height: 36px;
    letter-spacing: 0;

    @include sp() {
      font-size: 20px;
      line-height: 32px;
    }
  }
  &--h5 {
    font-family: $font-family-raleway;
    font-size: 20px;
    font-weight: font-weight('extra-bold');
    line-height: 32px;
    letter-spacing: 0;

    @include sp() {
      font-size: 18px;
      line-height: 28px;
    }
  }
  &--h6 {
    font-family: $font-family-raleway;
    font-size: 14px;
    font-weight: font-weight('bold');
    line-height: 24px;
    letter-spacing: 0;
  }
}
