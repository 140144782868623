.p-about {
  $root: &;

  overflow: hidden;

  &__section {
    $section: &;

    position: relative;
    padding-bottom: 112px;

    @include sp() {
      padding-bottom: 64px;
    }

    &-image {
      overflow: hidden;
      .a-mask-image__video {
        video {
          height: 100vw;
        }
      }
    }
    &-text {
      margin-top: 80px;

      @include sp() {
        margin-top: 35px;
      }

      #{$section}--head & {
        @include sp() {
          margin-top: 78px;
        }
      }

      &-title {
        margin-top: -4px;
        color: $color-primary-navy;
        text-align: center;

        #{$section}--0 & {
          margin-top: -7px;
        }

        @include sp() {
          margin-top: 0;
          text-align: left;
        }

        h1 {
          font-size: 30px;
          font-weight: font-weight('extra-bold');
          line-height: 48px;

          @include sp() {
            font-size: 24px;
            font-weight: font-weight('bold');
            line-height: 39px;
          }
        }
        h2 {
          width: 338px;
          margin-right: auto;
          margin-left: auto;
          font-size: 22px;
          font-weight: font-weight('bold');
          line-height: 36px;

          @include sp() {
            width: auto;
          }
        }
      }

      &-description {
        margin-top: 28px;
        color: $color-primary-navy;

        @include sp() {
          margin-top: 26px;
        }

        #{$section}--0 & {
          margin-top: 7px;
        }

        &--0 {
          @include sp() {
            margin-top: 9px;
          }
        }

        &-bullet {
          position: relative;
          padding-right: 40px;

          @include sp() {
            padding-right: 0;
            padding-left: 20px;
          }

          &::before {
            position: absolute;
            top: 9px;
            left: -16px;
            width: 6px;
            height: 6px;
            content: '';
            background: $color-secondary-red;
            border-radius: 50%;

            @include sp() {
              left: 0;
            }
          }
        }
      }
    }
    &--head {
      padding-bottom: 125px;

      @include sp() {
        padding-bottom: 71px;
      }

      &::after {
        position: absolute;
        bottom: -40px;
        left: 50%;
        z-index: 1;
        width: 1px;
        height: 80px;
        content: '';
        background: $color-primary-navy;
      }

      #{$section} {
        &-text {
          &-title {
            @include sp() {
              font-size: 24px;
              font-weight: font-weight('bold');
              text-align: center;
            }
          }
        }
      }
    }

    &--footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 480px;
      padding: 0;
      margin-top: 40px;
      background: $color-grey-100;

      #{$root}__logo-img {
        @include sp() {
          img {
            width: 190px;
            height: 60px;
          }
        }
      }

      i {
        position: absolute;
        top: -40px;
        left: 50%;
        width: 1px;
        height: 80px;
        content: '';
        background: $color-primary-navy;
      }
    }

    &--case-study {
      padding-bottom: 0;
    }
  }
}
