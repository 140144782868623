.a-mask-image {
  $root: &;

  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s;

  &--blending,
  &--played {
    opacity: 1;
  }

  picture {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;

    @include sp() {
      display: none;
    }

    #{$root}--blending & {
      opacity: 1;
    }

    video {
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-color: white;
      transform: scale(1.5);

      #{$root}--blending & {
        mix-blend-mode: screen;
      }
    }
  }
}
