@use "sass:math";
$gutter-pc: math.div($breakpoint-gutter-pc, 2);
$gutter-sp: math.div($breakpoint-gutter-sp, 2);

.o-row {
  display: flex;
  flex-wrap: wrap;
  padding-right: $gutter-pc;
  padding-left: $gutter-pc;
  margin-right: -$breakpoint-gutter-pc;
  margin-left: -$breakpoint-gutter-pc;

  &::after {
    display: table;
    clear: both;
    content: '';
  }

  @include sp() {
    justify-content: center;
    padding-right: $gutter-sp;
    padding-left: $gutter-sp;
  }
}
