.p-service-list {
  overflow: hidden;

  &__head {
    position: relative;
    height: 712px;

    @include sp() {
      height: auto;
    }

    &-wrapper {
      position: relative;
      height: 100%;

      & > .o-container {
        height: 100%;
        & > .o-row {
          height: 100%;
          & > .o-col {
            height: 100%;
          }
        }
      }
    }

    &-image {
      position: absolute;
      left: 50%;
      // width: calc(50% - 10px);
      width: 710px;
      margin-left: -720px;

      @include sp() {
        position: static;
        width: 100%;
        margin-left: 0;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      margin-top: -30px;

      @include sp() {
        padding-top: 77px;
        padding-bottom: 72px;
        margin-top: 0;
      }

      &-description {
        // margin-top: 15px;
        color: $color-primary-navy;

        @include sp() {
          margin-top: 8px;
        }
      }
    }
  }

  &__services {
    position: relative;
    z-index: 1;
    padding-bottom: 40px;
    margin-top: -80px;

    @include sp() {
      margin-top: 0;
    }

    &-wrapper {
      position: relative;
      z-index: 1;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include sp() {
        grid-template-columns: repeat(1, 1fr);
      }

      &-item {
        width: 578px;
        margin-bottom: 73px;

        &--right {
          justify-self: flex-end;
        }

        @include sp() {
          width: 100%;
          margin-bottom: 71px;
        }
      }
    }
  }

  &__card {
    &-head {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 96px 50px 96px 40px;
      background: rgba($color-grey-100, 0.95);
      border-radius: 3px;

      @include sp() {
        flex-direction: column;
        padding: 16px 20px 47px;
      }

      &-image {
        margin-right: 46px;

        @include sp() {
          margin-right: 0;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 322px;
        height: 132px;

        @include sp() {
          width: 100%;
          height: auto;
          margin-top: 8px;
        }
      }

      &-title {
        font-family: $font-family-raleway;
        font-size: 22px;
        font-weight: font-weight('bold');
        line-height: 36px;
        color: $color-primary-navy;
        letter-spacing: 0;
      }

      &-description {
        margin-top: 10px;
        font-size: 20px;
        line-height: 32px;
        color: $color-primary-navy;

        @include sp() {
          font-size: 19px;
          line-height: 36px;
        }
      }
    }
    &-detail {
      margin-top: 10px;

      @include sp() {
        margin-top: 8px;
      }

      &-item {
        padding-top: 27px;
      }
      &-title {
        font-family: $font-family-raleway;
        font-size: 22px;
        font-weight: font-weight('bold');
        line-height: 36px;
        color: $color-primary-navy;
        letter-spacing: 0;
      }

      &-description {
        margin-top: 10px;
        font-size: 16px;
        line-height: 32px;
        color: $color-primary-navy;
      }
    }
  }

  &__back {
    position: relative;
    z-index: 1;
  }

  &__graphic {
    position: absolute;
    bottom: -218px;
    left: 50%;
    width: 840px;
    height: 1052px;
    transform: translateX(346px);

    @include sp() {
      bottom: -160px;
      width: 479px;
      height: 545px;
      transform: translateX(-56px) rotate(20deg);
    }

    &-item {
      &--pc {
        display: block;
        @include sp() {
          display: none;
        }
      }
      &--sp {
        display: none;
        @include sp() {
          display: block;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      ellipse {
        isolation: isolate;
        mix-blend-mode: multiply;
      }
    }
  }
}
