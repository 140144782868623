.p-home {
  overflow: hidden;

  $hero-height: 900px;
  $hero-min-height: 712px;

  .o-section-hero {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: $hero-min-height;
    max-height: $hero-height;
    // min-height: 900px;
    // background: rgba($color-black, 0.2);

    @include sp() {
      justify-content: flex-start;
      height: auto;
      min-height: auto;
      max-height: none;
      background: $color-white;
      border-bottom: 1px solid rgba($color-grey-300, 11%);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;

      @include sp() {
        justify-content: flex-start;
      }
    }

    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      min-height: $hero-min-height;
      max-height: $hero-height;
      // min-height: 900px;
      overflow: hidden;

      @include sp() {
        height: 480px;
        min-height: auto;
        max-height: none;
      }

      &-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &-pc {
        display: block;

        @include sp() {
          display: none;
        }
      }

      &-sp {
        display: none;

        @include sp() {
          display: block;
        }
      }

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);

        @include sp() {
          width: auto;
          height: 100%;
        }
      }

      &--vertical {
        video {
          width: auto;
          height: 100%;
        }
      }
    }

    &__content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      @include sp() {
        justify-content: flex-start;
      }

      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        min-height: $hero-min-height;
        max-height: $hero-height;
        pointer-events: none;
        background: $color-white;

        @include sp() {
          height: 480px;
          min-height: auto;
          max-height: none;
        }
      }
      &-graphic {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100vh;
        min-height: $hero-min-height;
        max-height: $hero-height;
        // transform: translate(-50%, calc(-50% - 162px));

        @include sp() {
          height: 480px;
          min-height: auto;
          max-height: none;
        }

        &--pc {
          display: block;
          @include sp() {
            display: none;
          }
        }
        &--sp {
          display: none;
          @include sp() {
            display: block;
          }
        }

        svg {
          width: 100%;
          height: auto;
          // margin-top: -162px;
          margin-top: -18%;

          ellipse {
            isolation: isolate;
            mix-blend-mode: multiply;
          }

          @include sp() {
            height: 480px;
            margin-top: 0;
          }
        }
      }
      &-bg {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100vh;
        min-height: $hero-min-height;
        max-height: $hero-height;
        background: rgba($color-bg-dark-grey, 20%);

        @include sp() {
          height: 480px;
          min-height: auto;
          max-height: none;
        }
      }

      &-wrapper {
        position: relative;
        z-index: 3;

        & > .o-container {
          & > .o-row {
            & > .o-col {
              margin-right: auto;
              margin-left: auto;
            }
          }
        }
      }
      &-replay {
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 5;
        display: none;
        width: 40px;
        height: 40px;
        font-size: 20px;
        pointer-events: none;
        cursor: pointer;
        opacity: 0;
        transition: opacity 400ms;

        @include sp() {
          top: 430px;
          right: 10px;
          bottom: auto;
        }

        &--show {
          display: block;
          pointer-events: inherit;
          opacity: 1;
        }

        &:hover {
          opacity: 0.5;
        }
      }

      &-skip {
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 5;
        display: none;
        width: 40px;
        height: 40px;
        font-size: 20px;
        pointer-events: none;
        cursor: pointer;
        opacity: 0;
        transition: opacity 400ms;

        @include sp() {
          top: 430px;
          right: 10px;
          bottom: auto;
        }

        &--show {
          display: block;
          pointer-events: inherit;
          opacity: 1;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
      color: $color-white;

      &-top {
        @include sp() {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 480px;
          padding-right: 20px;
          padding-left: 20px;
          margin-right: -40px;
          margin-left: -40px;
        }
      }

      &-title {
        font-size: 40px;
        font-weight: font-weight('medium');
        line-height: 54px;
        text-align: center;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        @include sp() {
          margin-top: 20px;
          font-size: 24px;
          line-height: 39px;
          text-align: center;
        }
      }
      &-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &-subtitle {
        width: 640px;
        margin-top: 20px;
        font-size: 20px;
        font-weight: font-weight('medium');
        line-height: 32px;
        text-align: center;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        @include sp() {
          width: 100%;
          margin-top: 32px;
          font-size: 19px;
          line-height: 36px;
          color: $color-primary-black-navy;
          text-align: center;
          text-shadow: none;
        }

        &-br {
          display: block;
          @include sp() {
            display: none;
          }
        }
      }
      &-action {
        margin-top: 40px;

        @include sp() {
          margin-top: 32px;
          margin-bottom: 80px;
        }
      }
    }
    &__scroll {
      position: absolute;
      bottom: 40px;
      left: 50%;
      z-index: 4;
      width: 68px;
      margin-left: -34px;

      @include sp() {
        display: none;
      }

      &-text {
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: $color-primary-navy;
        transform: translateY(-10px);
      }
      &-line {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          height: 40px;
          content: '';
          background: $color-white;
        }
        &::after {
          position: absolute;
          top: 40px;
          left: 50%;
          width: 1px;
          height: 40px;
          content: '';
          background: $color-primary-navy;
        }
      }

      &-indicator {
        position: absolute;
        bottom: 50%;
        left: 50%;
        margin-top: -2px;

        &-bg {
          display: block;
          width: 4px;
          height: 4px;
          background: $color-secondary-red;
          border-radius: 50%;
        }
      }
    }
  }

  .o-section-service {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 860px;

    @include sp() {
      height: auto;
      padding-bottom: 80px;
    }

    &__graphic {
      position: absolute;
      bottom: -480px;
      left: 50%;
      pointer-events: none;
      transform: translateX(300px);

      @include sp() {
        bottom: -250px;
        transform: translateX(20px);
      }

      svg {
        width: 840px;
        height: 1052px;

        @include sp() {
          width: 519px;
          height: 650px;
        }
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      & > .o-container {
        height: 100%;
        & > .o-row {
          height: 100%;
          @include sp() {
            height: auto;
          }
          & > .o-col {
            height: 100%;
          }
        }
      }
    }

    &__heading-col {
      @include sp() {
        order: 1;
      }
    }
    &__heading {
      padding-top: 100px;

      @include sp() {
        padding-top: 75px;
      }
    }
    &__text-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include sp() {
        order: 3;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 48px;

      @include sp() {
        margin-top: 34px;
      }

      &-title {
        color: $color-primary-navy;
      }
      &-subtitle {
        width: 410px;
        padding-left: 4px;
        margin-top: 15px;
        color: $color-primary-navy;

        @include sp() {
          width: 100%;
          margin-top: 6px;
        }
      }
      &-action {
        padding-left: 4px;
        margin-top: 32px;

        @include sp() {
          margin-top: 39px;
        }
      }
    }
    &__image-col {
      @include sp() {
        order: 2;
        width: 100vw;
        margin-right: -60px;
        margin-left: -60px;
      }
    }
    &__image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @include sp() {
        height: 420px;
        margin-top: 34px;
      }

      &-item {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 600px;
        height: 736px;
        background: $color-white;

        @include sp() {
          position: static;
        }

        picture {
          display: block;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        @include sp() {
          width: 375px;
          height: 420px;
        }
      }

      &-text {
        position: absolute;
        top: 15px;
        right: 40px;
        font-family: $font-family-raleway;
        font-size: 300px;
        font-style: normal;
        font-weight: font-weight('bold');
        line-height: 1;
        color: $color-primary-navy;
        letter-spacing: 0;

        @include sp() {
          top: -72px;
          font-size: 200px;
        }
      }
    }
  }

  .o-section-product {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 980px;
    background-color: $color-grey-200;

    &__graphic {
      position: absolute;
      bottom: -452px;
      left: 50%;
      pointer-events: none;
      transform: translateX(395px);

      @include sp() {
        display: none;
      }

      svg {
        width: 840px;
        height: 1052px;

        @include sp() {
          width: 519px;
          height: 650px;
        }
      }
    }

    @include sp() {
      height: auto;
      padding-bottom: 100px;
    }

    & > .o-container {
      position: relative;
      height: 100%;
      & > .o-row {
        height: 100%;
        & > .o-col {
          height: 100%;
        }
      }
    }

    &__heading {
      margin-top: 100px;
      text-align: right;

      @include sp() {
        position: absolute;
        top: 76px;
        left: -18px;
        margin-top: 0;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 28px;

      @include sp() {
        margin-top: 3px;
      }

      &-title {
        color: $color-primary-navy;
        @include sp() {
          margin-right: -20px;
        }
      }
      &-subtitle {
        width: 460px;
        padding-left: 4px;
        margin-top: 15px;
        color: $color-primary-navy;

        @include sp() {
          width: 100%;
          margin-top: 7px;
        }
      }
      &-action {
        padding-left: 4px;
        margin-top: 32px;
      }
    }

    &__features {
      margin-top: 56px;

      @include sp() {
        margin-top: 77px;
      }

      &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 15px;

        @include sp() {
          grid-template-columns: repeat(1, 1fr);
          margin-top: 14px;
        }
      }

      &-item {
        @include sp() {
          margin-top: 8px;

          &:first-child {
            margin-top: 0;
          }
        }

        .a-br-sp {
          @include sp() {
            display: block;
          }
        }
      }
    }
    &__image-col {
      @include sp() {
        width: 100vw;
        margin-top: 144px;
        margin-right: -60px;
        margin-left: -60px;
      }
    }
    &__image {
      position: relative;
      height: 100%;

      @include sp() {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-item {
        position: absolute;
        top: 127px;
        right: 0;
        width: 710px;
        height: 712px;
        background: $color-white;

        @include sp() {
          position: static;
        }

        picture {
          display: block;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        video {
          background-color: $color-grey-200;
        }

        @include sp() {
          width: 375px;
          height: 420px;
        }
      }

      &-text {
        position: absolute;
        top: 15px;
        left: -22px;
        font-family: $font-family-raleway;
        font-size: 300px;
        font-style: normal;
        font-weight: font-weight('bold');
        line-height: 1;
        color: $color-white;
        letter-spacing: 0;

        @include sp() {
          top: -87px;
          right: 0;
          left: auto;
          font-size: 200px;
        }
      }
    }
  }
}
