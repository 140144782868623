$zindex-background: -1;
$zindex-neutral: 0;
$zindex-foreground: 1;
$zindex-sticky-register: 100;
$zindex-chat: 200;
$zindex-globalheader: 300;
$zindex-menu: 400;
$zindex-globalnavi: 500;
$zindex-sticky-comparison: 600;
$zindex-popup: 700;
$zindex-modal: 800;
