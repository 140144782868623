.p-legal {
  position: relative;
  overflow: hidden;

  &__head {
    position: relative;
    z-index: 1;
    margin-top: 242px;

    @include sp() {
      margin-top: 152px;
    }

    &-description {
      width: 770px;
      margin-top: 4px;
      color: $color-primary-navy;

      @include sp() {
        width: 100%;
        margin-top: 9px;
      }
    }
  }

  &__detail {
    &-list {
      position: relative;
      z-index: 1;
      margin-top: 110px;

      @include sp() {
        margin-top: 118px;
      }
    }
    &-item {
      position: relative;
      z-index: 1;
      padding-top: 55px;
      padding-bottom: 52px;
      border-bottom: 1px solid $color-line-blue-1;

      @include sp() {
        padding-top: 59px;
        padding-bottom: 69px;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      &--top {
        &:last-child {
          padding-bottom: 60px;
          margin-bottom: 55px;
          border-bottom: 1px solid $color-line-blue-1;
        }
      }

      &-title {
        font-family: $font-family-raleway;
        font-size: 20px;
        font-weight: font-weight('extra-bold');
        line-height: 32px;
        letter-spacing: 0;

        @include sp() {
          font-weight: font-weight('bold');
        }
      }
      &-description {
        width: 770px;
        margin-top: 9px;

        @include sp() {
          width: 100%;
        }
      }
    }
  }

  &__back {
    margin-top: 112px;

    @include sp() {
      margin-top: 132px;
    }
  }

  &__graphic {
    position: absolute;
    top: -89px;
    right: -670px;
    width: 884px;
    height: 701px;
    pointer-events: none;

    @include sp() {
      top: -48px;
      right: -514px;
      width: 667px;
      height: 528px;
    }

    svg {
      width: 100%;
      height: 100%;

      ellipse {
        isolation: isolate;
        mix-blend-mode: multiply;
      }
    }
  }
}
