// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700;800&display=swap');

/**
 * Font definition
 */
$font-family-raleway: 'Raleway', 'Helvetica', 'Arial', sans-serif;
$font-family-arial: 'Arial', sans-serif;

$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
