.p-contact-us {
  position: relative;
  padding-top: 168px;
  overflow: hidden;

  @include sp() {
    padding-top: 76px;
  }

  &__section {
    $section: &;

    position: relative;
    z-index: 1;
    padding-top: 74px;

    @include sp() {
      padding-top: 78px;
    }

    &--contact {
      padding-top: 74px;
    }

    &-title {
      margin-bottom: 6px;
    }
    &-text {
      color: $color-primary-navy;
      p {
        margin-bottom: 6px;
      }
      @include sp() {
        margin-top: 3px;

        #{$section}--contact & {
          margin-top: 5px;

          .a-text {
            font-family: $font-family-raleway;
            font-size: 20px;
            font-weight: font-weight('bold');
            line-height: 32px;
            letter-spacing: 0;
          }
        }
      }
    }
    &-line {
      margin-top: 110px;
      border-bottom: 1px solid $color-line-blue-1;

      @include sp() {
        margin-top: 80px;
      }
    }
    &--back {
      padding: 0;
      margin-top: 110px;
      background: $color-grey-100;

      @include sp() {
        margin-top: 92px;
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 40px;
    margin-left: -11px;
    font-family: $font-family-raleway;
    font-size: 13px;
    font-weight: font-weight('medium');
    line-height: 24px;
    color: $color-primary-black-navy;
    letter-spacing: 0.7px;
    cursor: pointer;
    border: 2px solid $color-primary-black-navy;
    border-radius: 28px;

    @include sp() {
      width: 100%;
      height: 56px;
      margin-top: 76px;
      margin-left: 0;
      font-size: 16px;
      line-height: 28px;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      display: block;
      width: 0;
      content: '';
      background-color: hsla(0, 0%, 80%, 0.1);
      opacity: 0;
      transition: width 500ms ease-out, opacity 400ms ease-in;
    }

    &:hover {
      &::after {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
        transition: width 500ms ease-out, opacity 300ms ease-out;
      }
    }
  }

  &__graphic {
    position: absolute;
    top: -89px;
    right: -670px;
    width: 884px;
    height: 701px;
    pointer-events: none;

    @include sp() {
      top: -48px;
      right: -514px;
      width: 667px;
      height: 528px;
    }

    svg {
      width: 100%;
      height: 100%;

      ellipse {
        isolation: isolate;
        mix-blend-mode: multiply;
      }
    }
  }
}
