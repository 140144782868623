.a-related-link {
  width: 64px;
  height: 64px;
  background-color: $color-base-white;
  border-radius: 50%;

  &--blue {
    background-color: $color-primary-blue;
  }
}
