.a-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  mask-type: alpha;
  mask-repeat: no-repeat;
  background-color: currentColor;
  background-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  &--text-prefix {
    margin-right: 0.5em;
    vertical-align: -0.13em;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      mask-size: 100% 100%;
    }
  }

  @mixin icon-color($name, $color) {
    &--#{$name} {
      color: $color;
    }
  }

  @include icon-color('white', $color-white);
  @include icon-color('dark-navy', $color-primary-dark-navy);
  @include icon-color('red', $color-secondary-red);

  @mixin icon-shape($name) {
    &--#{$name} {
      mask-image: url('~images/icons/#{$name}.svg');
    }
  }

  $icon-shapes: (
    'arrow-left-large',
    'arrow-right',
    'arrow-right-large',
    'close',
    'chevron-down',
    'chevron-up',
    'chevron-right',
    'ellipsis',
    'facebook',
    'gear',
    'jigsaw',
    'messenger',
    'replay',
    'search',
    'skip',
    'telegram',
    'twitter',
    'whatsapp'
  );

  @each $shape in $icon-shapes {
    @include icon-shape($shape);
  }
}
