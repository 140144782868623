.a-button {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 195px;
  height: 40px;
  padding-right: 50px;
  padding-left: 50px;
  font-family: $font-family-raleway;
  font-size: 13px;
  line-height: 24px;
  color: $color-white;
  letter-spacing: 0.7px;
  white-space: nowrap;
  cursor: pointer;
  background: $color-primary-black-navy;
  border-radius: 28px;
  transition: background-color 400ms ease-in;

  &:hover {
    background: $color-secondary-red;
  }

  @include sp() {
    min-width: 294px;
    height: 56px;
    padding-right: 74px;
    padding-left: 74px;
    font-size: 16px;
    line-height: 28px;
    transition: background-color 250ms;
  }

  &--icon {
    width: 40px;
    min-width: 0;
    padding: 0;
    background: transparent;

    &:hover {
      background: transparent;
    }

    @include sp() {
      height: 40px;
    }
  }

  &__icon {
    position: absolute;
    top: 4px;
    right: auto;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: $color-secondary-red;
    border-radius: 50%;
    transition: left ease-in-out 400ms, background-color 400ms ease-in;

    #{$root}--icon & {
      position: static;
      width: 20px;
      height: 20px;
      transition: background-color 250ms;
    }

    @include sp() {
      top: 14px;
      right: 16px;
      left: auto;
      width: 28px;
      height: 28px;
      transition: background-color 250ms;
    }

    #{$root}:hover & {
      left: calc(100% - 37px);
      background: $color-primary-dark-navy;

      @include sp() {
        right: 16px;
        left: auto;
      }
    }

    .a-icon {
      font-size: 32px;

      #{$root}--icon & {
        font-size: 20px;
      }

      @include sp() {
        font-size: 28px;
      }
    }
  }
  &__text {
    // z-index: 1;
  }
}
