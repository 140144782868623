.p-product-detail {
  position: relative;
  overflow: hidden;

  &__graphic {
    position: absolute;
    width: 840px;
    height: 105px;
    pointer-events: none;

    @include sp() {
      width: 666px;
      height: 834px;
    }

    svg {
      width: 100%;
      height: auto;
    }

    &--1 {
      top: -11px;
      left: 50%;
      transform: translateX(219px);

      @include sp() {
        top: -624px;
        transform: translateX(-248px);
      }
    }

    &--2 {
      top: 7px;
      left: 50%;
      transform: translateX(-1000px);

      @include sp() {
        top: auto;
        bottom: -454px;
        transform: translateX(-586px);
      }
    }
  }

  &__head {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
    margin-top: 155px;

    @include sp() {
      padding-bottom: 68px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-text {
      margin-top: 20px;
      text-align: center;

      @include sp() {
        margin-top: 28px;
      }
    }
    &-title {
      color: $color-primary-navy;
      h1 {
        font-size: 40px;
        line-height: 54px;

        @include sp() {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
    &-description {
      width: 598px;
      margin-top: 22px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
      line-height: 32px;
      color: $color-primary-navy;

      @include sp() {
        width: 100%;
        margin-top: 11px;
        font-size: 16px;
      }
    }
  }

  &__detail {
    position: relative;
    z-index: 1;
    &-title {
      padding-top: 70px;
      text-align: center;

      @include sp() {
        padding-top: 0;

        .a-heading {
          font-weight: font-weight('bold');
        }
      }
    }
  }

  &__value {
    padding-bottom: 172px;

    @include sp() {
      padding-bottom: 68px;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include sp() {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-item {
      width: 362px;
      height: 100%;
      margin-top: 52px;

      @include sp() {
        margin-top: 46px;
      }

      &--left {
        margin-right: 32.5px;
        margin-left: 0;
        @include sp() {
          margin-right: 0;
        }
      }
      &--center {
        margin-right: 32.5px;
        margin-left: 32.5px;
        @include sp() {
          margin-right: 0;
          margin-left: 0;
        }
      }
      &--right {
        margin-right: 0;
        margin-left: 32.5px;

        @include sp() {
          margin-left: 0;
        }
      }

      @include sp() {
        width: 100%;
        margin-top: 35px;
        margin-right: 0;
        margin-left: 0;
      }

      &-image {
        img {
          width: 100%;
          height: auto;
        }

        @include sp() {
          margin-right: -40px;
          margin-left: -40px;
        }
      }

      &-title {
        margin-top: 16px;
        font-family: $font-family-raleway;
        font-size: 22px;
        font-weight: font-weight('bold');
        line-height: 36px;
        color: $color-primary-navy;
        letter-spacing: 0;
      }
      &-description {
        margin-top: 4px;
        color: $color-primary-navy;

        @include sp() {
          margin-top: 8px;
        }
      }
    }
  }

  &__scope {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
      position: relative;
      z-index: 1;
    }

    &-title {
      margin-top: 0;

      @include sp() {
        display: none;
      }
      &-sp {
        display: none;
        @include sp() {
          display: block;
          margin-bottom: 34px;
        }
      }
    }

    &-image {
      margin-top: -20px;

      @include sp() {
        margin-top: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-description {
      margin-top: 26px;
      color: $color-primary-navy;

      @include sp() {
        margin-top: 34px;
      }

      a {
        color: $color-secondary-red;
        text-decoration: underline;
      }
    }
  }

  &__spec {
    position: relative;
    z-index: 1;
    margin-top: 120px;

    @include sp() {
      margin-top: 92px;
    }

    &-wrapper {
      padding-top: 63px;
      padding-bottom: 160px;
      background: rgba($color-grey-100, 0.95);

      @include sp() {
        padding-top: 18px;
        padding-bottom: 56px;
      }
    }

    &-detail {
      margin-top: 24px;
      border-top: 1px solid $color-primary-navy;

      @include sp() {
        margin-top: 41px;
      }
    }

    &-item {
      $item: &;

      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include sp() {
        grid-template-columns: repeat(1, 1fr);
      }

      #{$item}-text {
        width: 495px;
        height: 100%;
        padding-top: 26px;
        padding-bottom: 22px;
        color: $color-primary-navy;
        border-bottom: 1px solid $color-line-blue-1;
        transition: min-height 400ms ease-out;

        @include sp() {
          padding-top: 16px;
          padding-bottom: 15px;
        }

        &--right {
          justify-self: flex-end;
        }

        @include sp() {
          width: 100%;
        }

        a {
          color: $color-secondary-red;
          text-decoration: underline;
        }
      }
    }

    &-tab {
      display: flex;
      align-items: center;
      justify-content: center;

      .m-tabs {
        width: 740px;

        @include sp() {
          // width: 375px;
          width: auto;
        }
      }
    }
  }
  &__case-study {
    position: relative;
    z-index: 1;
  }
}
